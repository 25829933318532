import React, { useEffect, useState } from "react";
import { 
    Container,
    Row,
    Col,
    FormGroup,
    Card,
    CardBody,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import ReactSearchAutocomplete from "components/Search/ReactSearchAutocomplete";
import Products from "components/ui/Products";
import StandClient from "client";

const ProductsPage = (props) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        products: null,
        totalProductCount: 0,
        topProducts: [],
        currentPage: 1,
        perPage: (parseInt( StandClient.getConfigValueByName('products_per_page') ) || 21),
        lastPage: false,
        sortStr: 'populardesc',
        sortField: 'total_ordered_count',
        sortOrder: 'DESC',
        searchItems: [],
    })

    useEffect(() => {
        StandClient.getProducts(null, state.currentPage, state.perPage, state.search, state.sortField, state.sortOrder).then(result=>{
            setState(prev => {
                return {
                    ...prev,
                    products: result.landrickProducts,
                    totalProductCount: result.landrickProductCount.count,
                }
            })
        }).catch(error=>{
            console.log("ERROR!!!");
            console.log(error);
            setState(prev => {
                return {
                    ...prev,
                    products: []
                }
            })
        });
        StandClient.getDiscountProducts(5).then(result=>{
            setState(prev => {
                return {
                    ...prev,
                    topProducts: result.landrickDiscountProducts
                }
            })
        }).catch(error=>{
            console.log("ERROR!!!");
            console.log(error);
            setState(prev => {
                return {
                    ...prev,
                    topProducts: []
                }
            })
        });
    }, []);

    const changeSort = (newSortStr) => {
        setState(prev => {
            return {
                ...prev,
                products: [],
                currentPage: 1,
                lastPage: false,
                sortStr: newSortStr,
                sortField: StandClient.sortMap[newSortStr].sortField,
                sortOrder: StandClient.sortMap[newSortStr].sortOrder,
            }
        })
        StandClient.getProducts(null, 1, state.perPage, state.search, StandClient.sortMap[newSortStr].sortField, StandClient.sortMap[newSortStr].sortOrder).then(result=>{
            setState(prev => {
                return {
                    ...prev,
                    products: result.landrickProducts,
                    totalProductCount: result.landrickProductCount.count,
                }
            })
        }).catch(error=>{
            console.log("ERROR!!!");
            console.log(error);
            setState(prev => {
                return {
                    ...prev,
                    products: []
                }
            })
        });
    }

    const loadMore = () => {
        StandClient.getProducts(null, state.currentPage+1, state.perPage, state.search, state.sortField, state.sortOrder).then(result=>{
            let ps = state.products;
            result.landrickProducts.forEach((val)=>{
                ps.push(val);
            });
            setState(prev => {
                return {
                    ...prev,
                    products: ps,
                    totalProductCount: result.landrickProductCount.count,
                    currentPage: prev.currentPage+1
                }
            })
        }).catch(error=>{
            console.log("ERROR!!!");
            console.log(error);
            setState(prev => {
                return {
                    ...prev,
                    lastPage: true
                }
            })
        });
    }

    const formatResult = (item) => {
        if (item.type === 'suggest') {
            return (
                <>
                <span style={{ display: 'block', textAlign: 'left' }}><i>Вы имели в виду:</i></span>
                <span style={{ display: 'block', textAlign: 'left' }}><b>{item.name}</b></span>
                </>
            )
        }
        if (item.type === 'autocomplete') {
            return <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        }
        return (
            <table>
                <tr>
                    <td style={{width:48}}><img src={item.image} width={48} /></td>
                    <td>
                        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
                        <span style={{ display: 'block', textAlign: 'left' }}>{item.price}</span>
                    </td>
                </tr>
            </table>
        )
    }

    const conf = StandClient.getFrontConfigureLocal();
    document.title = 'Главная - ' + StandClient.getConfigValueByName('site_title');
    const hasSearch = StandClient.getConfigValueByName('_site_search_id');

    return (
        <React.Fragment>
            <section className="section">
                <Container>
                    <Row>
                        <Col lg={3} md={4} xs={12}>
                            <Card className="border-0 sidebar sticky-bar">
                                <CardBody className="p-0">
                                    {hasSearch === '' ? (null) : (
                                        <div className="widget">
                                            <div id="search2" className="widget-search mb-0">
                                                <ReactSearchAutocomplete
                                                    onSelect={(item) => navigate(item.url)}
                                                    showNoResultsText="Нет результатов"
                                                    formatResult={formatResult}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="widget mt-4 pt-2">
                                        <h5 className="widget-title">Категории</h5>
                                        <ul className="list-unstyled mt-4 mb-0 blog-categories">
                                            {conf.data.rootCategorys.map((cat,i)=> {
                                                return (<li key={i}><a href={"/category/"+cat.id+"/"}>{cat.name}</a></li>);
                                            })}
                                        </ul>
                                    </div>
                                    {state.topProducts.length === 0 ? (null) : (
                                        <div className="widget mt-4 pt-2">
                                            <h5 className="widget-title">Акции</h5>
                                            <ul className="list-unstyled mt-4 mb-0">
                                                {state.topProducts.map((product, key) => (
                                                    <li
                                                    key={key}
                                                    className={
                                                        key + 1 === 1
                                                        ? "d-flex align-items-center"
                                                        : "d-flex align-items-center mt-2"
                                                    }
                                                    >
                                                        <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"}>
                                                            <img
                                                                src={product.image}
                                                                className="img-fluid avatar avatar-small rounded shadow"
                                                                style={{ height: "auto" }}
                                                                alt="Landrick"
                                                            />
                                                        </a>
                                                        <div className="flex-1 content ms-3">
                                                            <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"} className="text-dark h6">
                                                                {product.name}
                                                            </a>
                                                            <h6 className="text-muted small fst-italic mb-0 mt-1">
                                                            {product.price}₽{" "}
                                                            <del className="text-danger ms-2">
                                                                {product.oldPrice}₽
                                                            </del>{" "}
                                                            </h6>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={9} md={8} xs={12} className="mt-5 pt-2 mt-sm-0 pt-sm-0">
                            <Row className="align-items-center">
                                <Col lg={8} md={7}>
                                    <div className="section-title">
                                        <h5 className="mb-0">Все товары</h5>
                                    </div>
                                </Col>
                                <Col lg={4} md={5} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div className="d-flex justify-content-md-between align-items-center">
                                        <div className="form custom-form">
                                            <FormGroup >
                                                <select className="form-select form-control" onChange={(e)=>{changeSort(e.target.value);}} >
                                                    <option value="populardesc">Сначала популярные</option>
                                                    <option value="priceasc">Сначала бюджетные</option>
                                                    <option value="pricedesc">Сначала дорогие</option>
                                                    <option value="iddesc">Сначала новые</option>
                                                </select>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Products loadMore={loadMore} state={state} />
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default ProductsPage;
