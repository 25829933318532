import PageBlockConstructor from "components/pageBlockConstructor";
import ShopCart from "components/pageBlockConstructor/pages/ShopCart";
import ShopCheckouts from "components/pageBlockConstructor/pages/ShopCheckouts";
import LastOrder from "components/pageBlockConstructor/pages/LastOrder";
import OldOrderDetail from "components/pageBlockConstructor/pages/OldOrderDetail";

const routes = [
    {
        path: '/',
        element: <PageBlockConstructor />,
    },
    {
        path: '/cart',
        element: <ShopCart />,
    },
    {
        path: '/lastOrder',
        element: <LastOrder />,
    },
    {
        path: '/checkout',
        element: <ShopCheckouts />,
    },
    {
        path: '/order/:id',
        element: <OldOrderDetail />,
    },
    {
        path: '/category/:id?',
        element: <PageBlockConstructor />,
    },
    {
        path: '/product/:id?',
        element: <PageBlockConstructor />,
    },
    {
        path: '/page/:id?',
        element: <PageBlockConstructor />,
    },
];

export default routes;
